import { AutoComplete, Col, Form, Input, Row, Select, Spin } from 'antd';
import { useEffect, useState } from 'react';
import {
  getCountriesSelectOptions,
  getTimezonesSelectOptions,
} from 'core/utils';
import { useCountries } from 'features/catalog';
import { SelectOptions } from 'core/types/SelectOptions';
import { useTranslation } from 'react-i18next';
import { facilityLabels } from 'components/view/Facility';
import { useSubpartnersQuery } from 'features/subpartners';
import {
  useIsSubpartner,
  usePartner,
  usePartnerId,
} from 'core/providers/PartnerProvider';
import { parkingTypesLabels } from 'core/types/ParkingType.enum';

import { Address } from '../Address';

import { LocationFormFieldsProps } from './types';

export const LocationFormFields = ({
  form,
  countryCode,
  timezone,
  setIsLoading,
  location,
  isCreate,
  setIsFormChanged,
}: LocationFormFieldsProps) => {
  const { t } = useTranslation();
  const { data: countries } = useCountries();
  const partnerId = usePartnerId();
  const partner = usePartner();
  const isSubpartner = useIsSubpartner();

  const { data: subpartners } = useSubpartnersQuery(
    partnerId,
    { size: 999 },
    { enabled: !isSubpartner },
  );

  const [timezones, setTimezones] = useState<SelectOptions[]>([]);
  const countrySelectOptions = getCountriesSelectOptions(countries || []);

  const owners = subpartners && partner && [partner, ...subpartners];

  useEffect(() => {
    const timezones = getTimezonesSelectOptions(
      countries?.find((c) => c.alfa3 === countryCode)?.timeZones || [],
    );

    setTimezones(timezones);

    if (timezones.length) {
      const isTimezoneAvailable = !!timezones.find((c) => c.value === timezone);

      if (!isTimezoneAvailable) {
        form.setFieldValue('timeZone', timezones[0].value);
      }
    }
  }, [countries, countryCode, form, timezone]);

  if (!countries) {
    return <Spin />;
  }

  return (
    <Row>
      <Col span={10}>
        {isCreate && (
          <Row>
            <Form.Item
              name="name"
              label={`${t('locations.labels.name')}:`}
              style={{ width: '100%' }}
            >
              <Input />
            </Form.Item>
          </Row>
        )}
        <Row>
          <Form.Item
            name="countryCode"
            label={`${t('locations.labels.country')}:`}
            style={{ width: '100%' }}
          >
            <Select options={countrySelectOptions} />
          </Form.Item>
        </Row>

        <Row>
          <Form.Item
            name="postalCode"
            label={`${t('locations.labels.postal-code')}:`}
          >
            <Input />
          </Form.Item>
        </Row>

        <Row>
          <Form.Item
            name="state"
            label={`${t('locations.labels.state')}:`}
            style={{ width: '100%' }}
          >
            <Input />
          </Form.Item>
        </Row>

        <Row>
          <Form.Item
            name="city"
            label={`${t('locations.labels.city')}:`}
            style={{ width: '100%' }}
          >
            <Input />
          </Form.Item>
        </Row>

        <Row>
          <Form.Item
            name="timeZone"
            label={`${t('locations.labels.timezone')}:`}
            style={{ width: '100%' }}
          >
            <AutoComplete options={timezones} />
          </Form.Item>
        </Row>

        <Row>
          <Form.Item
            name="facilities"
            label={`${t('locations.labels.facilities')}:`}
            style={{ width: '100%' }}
          >
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder={t('locations.placeholders.select-facilities')}
              options={Object.entries(facilityLabels).map(([key, value]) => ({
                value: Number(key),
                label: t(value),
              }))}
            />
          </Form.Item>
        </Row>

        <Form.Item
          name="ownerPartnerId"
          label={`${t('locations.labels.select-owner')}:`}
        >
          <Select
            placeholder={'Owner name'}
            options={(owners ?? []).map((owner) => ({
              label: owner.name,
              value: owner.id,
            }))}
          />
        </Form.Item>

        <Row>
          <Form.Item
            name="parkingType"
            label={`${t('locations.labels.parking-type')}:`}
            style={{ width: '100%' }}
          >
            <Select
              options={Object.entries(parkingTypesLabels).map(
                ([key, value]) => ({
                  value: key,
                  label: t(value),
                }),
              )}
            />
          </Form.Item>
        </Row>
      </Col>

      <Address
        form={form}
        onLoading={setIsLoading}
        coordinates={{
          lat: location?.lat ?? 0,
          lng: location?.lon ?? 0,
        }}
        setIsFormChanged={setIsFormChanged}
      />
    </Row>
  );
};
