import { useState } from 'react';
import { Button, Form, Divider, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocationCreateMutation } from 'features/locations/queries';
import { useIsSubpartner, usePartnerId } from 'core/providers/PartnerProvider';
import { useErrorTranslation } from 'i18n/useErrorTranslation';

import { LocationFormFields } from '../LocationFormFields';

import { FormValues, LocationCreateFormProps } from './types';

const initialFormValues: FormValues = {
  name: '',
  lat: 0,
  lon: 0,
  countryCode: '',
  state: '',
  city: '',
  address: '',
  postalCode: '',
  timeZone: '',
  facilities: [],
  parkingType: '',
  ownerPartnerId: '',
};

export const LocationCreateForm = ({}: LocationCreateFormProps) => {
  const partnerId = usePartnerId();
  const isSubpartner = useIsSubpartner();
  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const [isFormChanged, setIsFormChanged] = useState(false);

  const navigate = useNavigate();

  const [form] = Form.useForm<FormValues>();
  const countryCode = Form.useWatch('countryCode', form);
  const timezone = Form.useWatch('timeZone', form);

  const [isLoading, setLoading] = useState(false);

  const locationMutation = useLocationCreateMutation();

  const handleSubmit = (values: FormValues) => {
    locationMutation
      .mutateAsync([partnerId, values])
      .then(() => {
        form.resetFields();

        messageApi.open({
          type: 'success',
          content: t('common.status.success'),
        });

        setTimeout(() => {
          navigate('/locations');
        }, 1000);
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: errorT(error?.translationKey, error.message),
        });
      });
  };

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        layout={'vertical'}
        name="location"
        disabled={isLoading || isSubpartner}
        initialValues={initialFormValues}
        onFinish={handleSubmit}
      >
        <LocationFormFields
          isCreate
          form={form}
          countryCode={countryCode}
          timezone={timezone}
          setIsLoading={setLoading}
          setIsFormChanged={setIsFormChanged}
        />

        <Divider />

        <Button
          type="primary"
          htmlType="submit"
          loading={locationMutation.isLoading}
          disabled={!isFormChanged}
        >
          {t('common.actions.save-changes')}
        </Button>
      </Form>
    </>
  );
};
